// extracted by mini-css-extract-plugin
export var addressTitle = "style-module--address-title--75bf4";
export var addressValue = "style-module--address-value--66d0b";
export var containerAddress = "style-module--container-address--da690";
export var containerMap = "style-module--container-map--c6d10";
export var containerRequisites = "style-module--container-requisites--4a15a";
export var containerTopList = "style-module--container-top-list--ec66f";
export var description = "style-module--description--a62df";
export var requisitesItem = "style-module--requisites-item--5aad1";
export var requisitesItemTitle = "style-module--requisites-item-title--b0114";
export var requisitesItemValue = "style-module--requisites-item-value--0171f";
export var requisitesItems = "style-module--requisites-items--7462c";
export var requisitesTitle = "style-module--requisites-title--9a37c";
export var title = "style-module--title--2928e";
export var topItem = "style-module--top-item--4c02b";
export var topItemDescription = "style-module--top-item-description--841b3";
export var topItemLink = "style-module--top-item-link--7f32f";
export var topItemTitle = "style-module--top-item-title--008bc";